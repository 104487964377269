<template>
  <div class="desktopLayoutClass">
    <v-app style="overflow-y: scroll">
      <!-- Dialog Start -->
      <div v-if="frequencyPopup">
        <v-row justify="center">
          <v-dialog v-model="frequencyPopup" persistent max-width="calc(100vw - 20px)">
            <v-card class="pa-4">
              <v-card-text class="pa-0">
                <div class="d-flex flex-column align-center pa-0">
                  <div>
                    <img src="https://s3iconimages.mymedicine.com.mm/success_tick.svg"> 
                  </div>
                  <div class="py-2">
                    <p v-if="$i18n.locale=='en'" class="ma-0 pb-1" style="text-align: left;">
                      <span>{{ $t("Customer.VerifyAppointment.Frequency_Popup1") }}</span>
                      <span style="font-weight: 700;">{{ corporatePackageName }}. </span>
                    </p>
                    <p v-else class="ma-0 pb-1" style="text-align: left;">
                      <span style="font-weight: 700;">{{ corporatePackageName }}. </span>
                      <span>{{ $t("Customer.VerifyAppointment.Frequency_Popup1") }}</span>
                    </p>
                    <p class="ma-0" style="text-align: left; color: #F89035;">
                      <span>{{ $t("Customer.Myoffers.Condition") }} : 
                        <span>{{ booking_details.frequency.booking_count }} {{ $t('Customer.Myoffers.Frequency_Bookings') }}</span>
                        /
                        <span>{{ booking_details.frequency.number_of_days }} {{ $t('Customer.Myoffers.Frequency_Days') }}</span>
                      </span>
                    </p>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-center">
                  <v-btn depressed width="40%"
                    color="primary" @click="closeFrequencyPopup()">
                    {{$t("Warning_Message.Ok")}}
                  </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div justify="center">
        <v-dialog v-model="cannotProceedFlag" persistent max-width="290">
          <v-card>
              <v-card-title style="word-break:normal">
                  {{ $t("Warning_Message.Cant_Proceed") }}
              </v-card-title>
              <v-card-actions>
                  <v-btn color="primary" depressed @click="closeCantProceedPopup" style="margin: auto;">
                      {{ $t("Warning_Message.Home_Page") }}
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.Unauthorized") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.Something_Went_Wrong") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeDialogError">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="restart_app" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.Error_Saving_Data") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeRestartApp">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="order_expired" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Warning_Message.TimedOut_Error") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeOrderExpired">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="slotBooked" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal; font-size: 1rem;">
                {{ $t("Warning_Message.select_New_Time_Slot") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="redirectToBookingPage">
                  {{ $t("Warning_Message.Ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- Dialog Close -->

      <div
        class="header"
        v-show="show_header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
        "
      >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToBookingPage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{
              $t("Customer.VerifyAppointment.Verify_Booking")
            }}</span>
          </div>
        </div>
      </div>

      <v-container fluid style="padding: 10px 20px !important">
        <div
          v-if="!isLoadingAppointmentDetails"
          style="overflow: scroll; height: 65vh"
          >
          <div style="display: flex; flex-direction: column" align="left">
            <div v-if="!freeConsultation">
              <v-card
                v-if="!couponStatus && !packageStatus && !offerStatus"
                style="padding: 5px 5px 0px 5px; box-shadow: unset;"
                @click="redirectToMyOffers"
                >
                <div class="myOfferClass">
                  <div class="myOffertitle">
                    <div>
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/myOffer.svg"
                      />
                    </div>
                    <div style="margin-left: 5px; margin-top: 2px">
                      <h4 v-if="!couponStatus" class="offersTextCalss">
                        {{ $t("Customer.VerifyAppointment.My_Offers") }}
                      </h4>
                      <h4 v-else class="offersTextCalss">{{ $t("Customer.VerifyAppointment.Offer_Applied") }}</h4>
                    </div>
                  </div>
                  <div>
                    <div class="arrowClass">
                      <img
                        src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
                      />
                    </div>
                  </div>
                </div>
                <p class="multiplePackagesClass" v-if="multiple_packages_notification">{{ $t("Customer.VerifyAppointment.Multiple_Packages_Notification") }}</p>
                <p class="multiplePackagesClass" v-if="multiple_offers_notification">{{ $t("Customer.VerifyAppointment.Multiple_Offers_Notification") }}</p>

              </v-card>


              <v-card class="myOfferappliedClass"  v-if="couponApplied || packageApplied || offerApplied">
                <div style="padding: 5px">
                  <div class="OffertitleRow">
                    <div class="myOffertitle">
                      <div>
                        <img
                          src="https://s3iconimages.mymedicine.com.mm/myOffer.svg"
                        />
                      </div>
                      <div style="margin-left: 5px; margin-top: 2px">
                        <h4
                          v-if="booking_details.offerType == 'PROMOTION'"
                          class="offersTextCalss"
                          >
                          {{ $t("Customer.VerifyAppointment.Used_Promotion") }}
                        </h4>
                        <h4
                          v-else-if="corporatePackageName"
                          >{{ $t("Customer.VerifyAppointment.Package_Used") }}
                        </h4>
                        <h4 v-else class="offersTextCalss">{{ $t("Customer.VerifyAppointment.Used_Coupon") }}</h4>
                      </div>
                    </div>
                    <div>
                      <div class="arrowClass">
                        <img
                          @click="cancelCoupon"
                          src="@/assets/popupClose.svg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="offerdescription" style="padding: 5px">
                  <div style="display: flex; flex-direction: row">
                    <div>
                      <img
                        style="width: 16px; margin: 0px 6px 0px 6px"
                        src="https://s3iconimages.mymedicine.com.mm/success_tick.svg"
                      />
                    </div>
                    <div>
                      <p class="couponCodeDescription" v-if="couponApplied">
                        {{ couponCode }} {{ $t("Customer.VerifyAppointment.Applied") }}
                      </p>
                      <p v-else-if="packageStatus" class="couponCodeDescription">
                        Package {{ corporatePackageName }} {{ $t("Customer.VerifyAppointment.Applied") }}
                      </p>
                      <p v-else-if="offerStatus" class="couponCodeDescription">
                        Promotion {{ offerName }} {{ $t("Customer.VerifyAppointment.Applied") }}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p class="discountedAmountDescription">
                      - {{ discountAmount }} MMK
                    </p>
                  </div>
                </div>
              </v-card>
            </div>
            <v-card
              style="
                box-shadow: 0px 8px 20px 0px #0547891a;
                border: 1px solid #e4f5ff;
                border-radius: 8px;
              "
            >
              <div
                style="display: flex; flex-direction: row; padding: 10px 20px"
              >
                <div style="flex: 1">
                  <span class="css-title"
                    >{{ $t("Customer.VerifyAppointment.Name") }}:</span
                  >
                </div>
                <div style="flex: 1; text-align: right">
                  <span class="content">{{
                    booking_details.bookedForName
                  }}</span>
                </div>
              </div>
              <v-divider />
              <div
                style="display: flex; flex-direction: row; padding: 10px 20px"
              >
                <div style="flex: 1">
                  <span class="css-title">
                    {{ $t("Customer.VerifyAppointment.Specialization") }}:</span
                  >
                </div>
                <div style="flex: 1; text-align: right">
                  <span class="content">{{
                    booking_details.specialization_display_name
                  }}</span>
                </div>
              </div>
              <v-divider />
              <div
                style="display: flex; flex-direction: row; padding: 10px 20px"
              >
                <div style="flex: 1">
                  <span class="css-title">
                    {{ $t("Customer.VerifyAppointment.Doctor") }}:</span
                  >
                </div>
                <div style="flex: 1; text-align: right">
                  <span class="content">{{
                    booking_details.doctorDisplayName
                  }}</span>
                </div>
              </div>
              <v-divider />
              <div
                style="display: flex; flex-direction: row; padding: 10px 20px"
              >
                <div style="flex: 1">
                  <span class="css-title">
                    {{ $t("Customer.VerifyAppointment.Date") }}:</span
                  >
                </div>
                <div style="flex: 1; text-align: right">
                  <span class="content">
                    <DateRendered :dateVal="booking_details.dateOfAppointment">
                    </DateRendered>
                  </span>
                </div>
              </div>
              <v-divider />
              <div
                style="display: flex; flex-direction: row; padding: 10px 20px"
              >
                <div style="flex: 1">
                  <span class="css-title">
                    {{ $t("Customer.VerifyAppointment.Time") }}:</span
                  >
                </div>
                <div style="flex: 1; text-align: right">
                  <span class="content">{{ booking_details.displayTime }}</span>
                </div>
              </div>
              <v-divider />
              <div style="padding: 10px 20px">
                <div style="flex: 1">
                  <span class="css-title">
                    {{ $t("Customer.VerifyAppointment.Description") }}:</span
                  >
                </div>
                <div
                  style="
                    flex: 1;
                    text-align: left;
                    overflow: scroll;
                    height: 10vh;
                    overflow-x: hidden;
                  "
                >
                  <span class="content">{{ booking_details.description }}</span>
                </div>
              </div>
            </v-card>
          </div>
          <div style="margin: 20px 10px 10px 0; display: flex; justify-content: center;" >
            <v-text-field
              v-model="referralCode"
              outlined
              placeholder="Enter Referral Code"
              label="Enter Referral Code"
              style=" height: 60px;"
              class="centered-placeholder"
            />
          </div>
          <div class="paymentGatewayContainer" v-if="miniApp == false && mobileBrowser == false && platform!='AYA' && platform!='CBPay'">
              <div class="selectPaymentBox">
                Select Payment
              </div>
              <!-- <a href="ayapay://mLink/screen=DynamicConfirm?requestedId=< reference number from API response >" >dsds</a> -->

              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="kbzPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="">
                        <div>KBZ Pay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'kbzPay'" style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('KBZQR')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/kbzQR.svg" alt="" >
                    <div>Generate QR</div>
                    <img v-if="selectedPaymentMethod === 'KBZQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
              </div>
              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="ayaPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/ayaPayLogo.svg" alt="" style="width: 32px; height: 32px;">
                        <div>Aya Pay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'ayaPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAREQ')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayNoCircle.svg" alt="">
                    <div>Phone No</div>
                    <img v-if="selectedPaymentMethod === 'AYAREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAQR')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayQR.svg" alt="">
                    <div>Generate QR</div>
                    <img v-if="selectedPaymentMethod === 'AYAQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">

                  </div>
                </div>
                <div v-if="selectedPaymentRadio=='ayaPay' && selectedPaymentMethod == 'AYAREQ'" style="width: 95%;">
                  <v-text-field
                    label="Phone Number"
                    outlined
                    Dense
                    v-model="appPhoneNumber"
                  ></v-text-field>                
          </div>
              </div>
              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="CBPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/CBPay_Logo.svg" alt="" style="width: 32px; height: 32px;">
                        <div>CBPay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'CBPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                  <!-- <div class="generateQRBOX" @click="() => {updatePaymentMethod('CBPayREQ')}">
                    <img style="width: 32px; height: 32px;" src="https://s3iconimages.mymedicine.com.mm/CBPay_Logo.svg" alt="">
                    <div>CBPay</div>
                    <img v-if="selectedPaymentMethod === 'CBPayREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div> -->
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('CBPayQR')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayQR.svg" alt="">
                    <div>Generate QR</div>
                    <img v-if="selectedPaymentMethod === 'CBPayQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
                </div>
              </div>
          </div>
          <div class="paymentGatewayContainer" v-if="miniApp == false && mobileBrowser == true && platform!='AYA' && platform!='CBPay'" >
              <div class="selectPaymentBox">
                Select Payment
              </div>
              <!-- <a href="ayapay://mLink/screen=DynamicConfirm?requestedId=< reference number from API response >" >dsds</a> -->

              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="kbzPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/KBZPay_icon.svg" alt="">
                        <div>KBZ Pay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'kbzPay'" style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('KBZREQ')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/kbzQR.svg" alt="">
                    <div>Open KBZ Pay</div>
                    <img v-if="selectedPaymentMethod === 'KBZREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
              </div>
              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="ayaPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/ayaPayLogo.svg" alt="" style="width: 32px; height: 32px;">
                        <div>Aya Pay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'ayaPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('AYAREQ')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayNoCircle.svg" alt="">
                    <div>Phone No</div>
                    <img v-if="selectedPaymentMethod === 'AYAREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
                </div>
                <div v-if="selectedPaymentRadio=='ayaPay' && selectedPaymentMethod == 'AYAREQ'" style="width: 95%;">
                  <v-text-field
                    label="Phone Number"
                    outlined
                    Dense
                    v-model="appPhoneNumber"
                  ></v-text-field>                
          </div>
              </div>
              <div class="gatewayBox">
                <v-radio-group v-model="selectedPaymentRadio" @change="() => {selectedPaymentMethod = ''}">
                  <v-radio value="CBPay">
                    <template v-slot:label>
                      <div style="display: flex; align-items: center; gap: 10px;">
                        <img src="https://s3iconimages.mymedicine.com.mm/CBPay_Logo.svg" alt="" style="width: 32px; height: 32px;">
                        <div>CBPay</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div v-if="selectedPaymentRadio === 'CBPay'"  style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 16px; gap: 5px;">
                <div>Pay Using</div>
                <div style="display: flex; gap: 10px; margin-bottom: 16px;">
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('CBPayREQ')}">
                    <img style="width: 32px; height: 32px;" src="https://s3iconimages.mymedicine.com.mm/CBPay_Logo.svg" alt="">
                    <div>CBPay</div>
                    <img v-if="selectedPaymentMethod === 'CBPayREQ'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">
                  </div>
                  <div class="generateQRBOX" @click="() => {updatePaymentMethod('CBPayQR')}">
                    <img src="https://s3iconimages.mymedicine.com.mm/ayaPayQR.svg" alt="">
                    <div>Generate QR</div>
                    <img v-if="selectedPaymentMethod === 'CBPayQR'" src="https://s3iconimages.mymedicine.com.mm/tickBlue.svg" alt="">

                  </div>
                </div>
              </div>
          </div>
          <div>
            <br />
            <div style="width: 100%; margin-bottom: 65px; padding-bottom: 40px">
              <div
                style="
                  background-color: #e4f5ff;
                  text-align: left;
                  border-radius: 4px;
                  padding: 16px;
                "
              >
                <div
                  style="
                    font-weight: 400px;
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                  "
                >
                  <h4 style="font-weight:400;">  {{ $t("Customer.VerifyAppointment.Reschedule_Title1") }}
                  <font style = "color:red;" >   {{ $t("Customer.VerifyAppointment.Reschedule_Title2") }} </font>
                   {{ $t("Customer.VerifyAppointment.Reschedule_Title3") }}  </h4>
                   <br>
                </div>

                <div
                  style="font-weight: 300; font-size: 14px; line-height: 24px ; color:black;"
                >
                  <p>{{ $t("Customer.VerifyAppointment.HotLine") }}</p>
                  <p>
                    {{ $t("Customer.VerifyAppointment.Reschedule_Notice") }}
                  </p>
                  <p>{{ $t("Customer.VerifyAppointment.Cancel_Notice") }}</p>
                </div>

                <div
                  style="
                    font-weight: 400px;
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                  "
                >
                  <h4 style="font-weight:400;"> {{ $t("Customer.VerifyAppointment.Reschedule_Request") }}
                  </h4>
                  
                   <br>
                </div>
                 <div
                  style="font-weight: 300; font-size: 14px; line-height: 24px ; color:black;"
                >
                  <ul>
                  <li>{{ $t("Customer.VerifyAppointment.Reschedule_Request_Body") }}</li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

      </v-container>
      <div
        v-if="!isLoadingAppointmentDetails"
        style="
          position: fixed;
          bottom: 10px;
          width: 100%;
          max-width: 425px;
          margin-left: auto;
          margin-right: auto;
          background-color: #fff;
          padding: 20px 20px;
        "
        >
        
        <div>
          <v-checkbox v-if="customer_name =='Guest'" v-model="check1">
            <template v-slot:label>
              <div>
                {{ $t("Customer.VerifyAppointment.Allow_User_Data") }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox v-model="check2" v-if="consent_acceptance == 'No' || consent_acceptance == 'Changed' || consent_acceptance == 'Denied'">
            <template v-slot:label>
              <div>
                {{ $t("Customer.VerifyAppointment.First_Consent_Request_Part1") }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click="redirectToTermsAndCondition"
                    >
                      {{ $t("Customer.VerifyAppointment.Terms_And_Condition_Link") }}
                    </a>
                  </template>
                </v-tooltip>
                {{ $t("Customer.VerifyAppointment.First_Consent_Request_Part2") }}
              </div>
            </template>
          </v-checkbox>
        </div>
        
        <div
          v-if="!freeConsultation && !couponStatus && !packageStatus && !offerStatus"
          style="
            display: flex;
            flex-direction: row;
            background-color: #eaf4ff;
            border-radius: 4px;
            text-align: left;
            padding: 16px 20px;
          "
          >
          <div style="flex: 1">
            <span style="font-weight: 500; size: 16px; line-height: 20px">{{
              $t("Customer.VerifyAppointment.Price")
            }}</span>
          </div>
          <div style="flex: 1; text-align: right">
            <span
              v-if="!freeConsultation"
              style="font-weight: 500; size: 16px; line-height: 20px"
            >
              {{ booking_details.amount
              }}{{ $t("Customer.VerifyAppointment.MMK") }}</span
            >
            <!-- <span v-if="freeConsultation" style="font-weight: 500; size: 16px; line-height: 20px;"><span style="text-decoration:line-through;">{{booking_details.amount}}</span>&nbsp;&nbsp;&nbsp;0 {{ $t("Customer.VerifyAppointment.MMK") }}</span> -->
            <!-- <span v-if="couponStatus && !freeConsultation" style="font-weight: 500; size: 16px; line-height: 20px;"><span style="text-decoration:line-through;">{{booking_details.amount}}</span>&nbsp;&nbsp;&nbsp;{{amountAfterDiscount}} {{ $t("Customer.VerifyAppointment.MMK") }}</span> -->
          </div>
        </div>
        <div v-else-if="offerStatus">
          <div
            style="
              display: flex;
              flex-direction: row;
              background-color: #eaf4ff;
              border-radius: 4px;
              text-align: left;
              padding: 16px 20px;
            "
          >
            <div style="flex: 1">
              <span style="font-weight: 500; size: 16px; line-height: 20px"
                >{{ $t("Customer.VerifyAppointment.Price") }}
                <span style="text-decoration: line-through; padding: 0px 8px"
                  >{{ booking_details.amount }}
                  {{ $t("Customer.VerifyAppointment.MMK") }}</span
                >
              </span>
            </div>

            <div style="flex: 1; text-align: right">
              <span
                v-if="offerStatus && !freeConsultation"
                style="font-weight: 500; size: 12px; line-height: 20px"
                ><span style="text-decoration: line-through"></span
                >&nbsp;&nbsp;{{ amountAfterDiscount }}
                {{ $t("Customer.VerifyAppointment.MMK") }}</span
              >
            </div>
          </div>
        </div>
        <div v-else-if="couponStatus">
          <div
            style="
              display: flex;
              flex-direction: row;
              background-color: #eaf4ff;
              border-radius: 4px;
              text-align: left;
              padding: 16px 20px;
            "
          >
            <div style="flex: 1">
              <span style="font-weight: 500; size: 16px; line-height: 20px"
                >{{ $t("Customer.VerifyAppointment.Price") }}
                <span style="text-decoration: line-through; padding: 0px 8px"
                  >{{ booking_details.amount }}
                  {{ $t("Customer.VerifyAppointment.MMK") }}</span
                >
              </span>
            </div>

            <div style="flex: 1; text-align: right">
              <span
                v-if="couponStatus && !freeConsultation"
                style="font-weight: 500; size: 12px; line-height: 20px"
                ><span style="text-decoration: line-through"></span
                >&nbsp;&nbsp;{{ amountAfterDiscount }}
                {{ $t("Customer.VerifyAppointment.MMK") }}</span
              >
            </div>
          </div>
        </div>
        <div v-else-if="packageStatus">
          <div
            style="
              background-color: #27ae60;
              border-radius: 4px 4px 0px 0px;
              color: white;
              text-align: left;
              padding: 0px 20px;
            "
            >
            <p
              style="margin-bottom: 0px !important; padding: 10px 0px"
              v-if="$i18n.locale =='en'"
              >
              {{ $t("Customer.VerifyAppointment.Package") }} {{ corporatePackageName }}
            </p>
            <p
              style="margin-bottom: 0px !important; padding: 10px 0px"
              v-if="$i18n.locale =='mm'"
              >
              {{ corporatePackageName }} {{ $t("Customer.VerifyAppointment.Package") }}
            </p>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              background-color: #eaf4ff;
              border-radius: 4px;
              text-align: left;
              padding: 16px 20px;
            "
            >
            <div style="flex: 1">
              <span style="font-weight: 500; size: 16px; line-height: 20px"
                >{{ $t("Customer.VerifyAppointment.Price") }}
                <span style="text-decoration: line-through; padding: 0px 8px"
                  >{{ booking_details.amount }}
                  {{ $t("Customer.VerifyAppointment.MMK") }}</span
                >
              </span>
            </div>

            <div style="flex: 1; text-align: right">
              <!-- <span v-if="!freeConsultation" style="font-weight: 500; size: 16px; line-height: 20px;"> {{booking_details.amount}} @@ {{ $t("Customer.VerifyAppointment.MMK") }}</span> -->
              <span
                style="font-weight: 500; size: 16px; line-height: 20px"
                >
                &nbsp;&nbsp;{{ amountAfterDiscount }} {{ $t("Customer.VerifyAppointment.MMK") }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="!paymentLoading">
          <v-btn
            v-if="(!freeConsultation && amountAfterDiscount !== 0 && platform!='AYA')"
            style="
              width: 100% !important;
              height: 48px;
              color: white;
              background-color: #1467bf;
            "
            @click="redirectToPayment()"
            :disabled="isBookSlot || !checked_vars()  || (miniApp == false && (selectedPaymentRadio == '' || selectedPaymentMethod == '' || (selectedPaymentMethod == 'AYAREQ' && appPhoneNumber == '')))"
            >{{ $t("Customer.VerifyAppointment.Pay_Now_To_Book") }}</v-btn
          >
          <v-btn
            v-if="platform=='AYA'"
            style="
              width: 100% !important;
              height: 48px;
              color: white;
              background-color: #1467bf;
            "
            @click="redirectToAyaPaymentApp()"
            :disabled="isBookSlot || !checked_vars()"
            >{{ $t("Customer.VerifyAppointment.Pay_Now_To_Book") }} Via AYAPAY</v-btn
          >
          
        </div>
        <div v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p>{{ $t("Customer.VerifyAppointment.Payment_Processing") }}</p>
        </div>
        <div v-if="!paymentLoading">
          <v-btn
            v-if="(freeConsultation || amountAfterDiscount === 0)"
            style="
              width: 100% !important;
              height: 48px;
              color: white;
              background-color: #1467bf;
            "
            @click="redirectToPayment()"
            :disabled="isBookSlot || !checked_vars()"
          >{{ $t("Customer.VerifyAppointment.Confirm_Booking") }}</v-btn>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
import DateRendered from "./dateRender.vue";
export default {
  name: "CustomerHome",
  data() {
    return {
      platform: '',
      booking_details: "",
      isBookSlot: false,
      auth_error_enable: false,
      error_enable: false,
      order_expired: false,
      customer_id: "",
      customer_name: "",
      currentCustomer: "",
      isMobile: false,
      freeConsultation: false,
      isLoadingAppointmentDetails: true,
      slotBooked: false,
      paymentStatus: false,
      loading_indication: "",
      timeout_count: 1,
      kbzEmployee: "unchecked",
      paymentLoading: false,
      show_header: true,
      couponCode: "",
      couponStatus: null,
      couponResponse: "",
      discountAmount: "",
      amountAfterDiscount: "",
      couponErrorStatusCode: "",
      minAmountToBeSpent: "",
      couponApplied: false,
      corporate_details: [],
      packageStatus: false,
      corporatePackageName: "",
      multiple_packages_notification: false,
      packageApplied: false,
      check1: true,
      check2: true,
      consent_acceptance: "",
      restart_app: false,
      cannotProceedFlag: false,
      frequencyPopup: false,
      offerFullInfo: null,
      offerAutoApplied: false,
      offerStatus: false,
      offerApplied: false,
      offerName: '',
      allPromoList: [],
      multiple_offers_notification: false,
      miniApp: false,
      selectedPaymentRadio: '',
      selectedPaymentMethod: '',
      appPhoneNumber: '',
      mobileBrowser: false,
      ayaPhoneNumber: '',
      referralCode: ''
    };
  },
  components: {
    NavBar,
    bottomnavbar,
    DateRendered,
  },
  methods: {
    updatePaymentMethod(value) {
      this.selectedPaymentMethod = value;
    },
    closeFrequencyPopup() {
      this.frequencyPopup = false;
    },
    closeCantProceedPopup() {
      this.cannotProceedFlag = false;
      this.$router.push({
        name: 'CustomerHomeScreen'
      });
    },
    closeRestartApp()
    {
      this.restart_app = false;
      kbzpay.gotoFunction('native://kbz/customer/homepage/wallet');
    },
    redirectToTermsAndCondition(){
      this.$router.push({
        name: "TermsAndConditions",
      });
    },
    checked_vars()
    {
      if(this.check1 && this.check2)
        return true;
      else
        return false;
    },
    cancelPackage()
    {

      this.$nextTick(()=>{
        this.packageStatus = false;
      });
    },
    query_order(queryOrderBody) {
      var query_order_function = setInterval(() => {
        axios
          .post(
            process.env.VUE_APP_BACKEND_URL + "/kbzPayQueryOrderCall",
            queryOrderBody
          )
          .then((queryResponse) => {
            var trade_status = queryResponse.data.data.trade_status;
            if (trade_status == "PAY_SUCCESS") {
              this.paymentStatus = false;
              clearInterval(query_order_function);
              this.$router.push({
                path:
                  "/customer/bookedSuccess/?merch_order_id=" +
                  String(queryOrderBody.booking_id),
              });
            } else if (trade_status == "WAIT_PAY" || trade_status == "PAYING") {
              this.timeout_count += 1;
              if (this.$i18n.locale == "en") {
                this.loading_indication =
                  "Waiting for payment: " + this.timeout_count.toString();
              } else {
                this.loading_indication =
                  "ငွေပေးချေမှုအတွက် စောင့်ဆိုင်းနေပါသည်: " +
                  this.timeout_count.toString();
              }
              if (this.timeout_count >= 60) {
                clearInterval(query_order_function);
                this.order_expired = true;
              }
            } else {
              clearInterval(query_order_function);
              this.error_enable = true;
            }
          })
          .catch((error) => {
            clearInterval(query_order_function);
            this.error_enable = true;
          });
      }, 5000);
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    closeDialogError() {
      this.error_enable = false;
      if (localStorage.getItem("BookingDetails")) {
        let localData = localStorage.getItem("BookingDetails");
        this.$router.push({
            name: 'newBookingPage',
            params: { docId: localData.doctorId, walkIn: localData.bookingType}
        });
      } else {
        this.$router.push({
          name: 'CustomerHomeScreen'
        });
      }
    },
    closeOrderExpired() {
      this.order_expired = false;
      if (localStorage.getItem("BookingDetails")) {
        let localData = localStorage.getItem("BookingDetails");
        this.$router.push({
            name: 'newBookingPage',
            params: { docId: localData.doctorId, walkIn: localData.bookingType}
        });
      } else {
        this.$router.push({
          name: 'CustomerHomeScreen'
        });
      }
    },
    redirectToBookingPage() {
      this.$router.go(-1);
    },
    redirectToPayment() {
        this.paymentLoading = true;
        var changeConsentBody = {
            'consentStatus' : 'Accepted',
            // 'customerId': this.customer_id,
            'token' : this.currentCustomer,
            'typeOfUser' : "CUSTOMER"
        }
        if(this.customer_name == 'Guest' && this.check1)
        {
            axios.post(process.env.VUE_APP_BACKEND_URL+"/postConsentAcceptance", changeConsentBody)
            .then((changeSuccess) => {
            })
            .catch((changeError) =>{
            });
        }
      this.isBookSlot = true;
      var userAgent = navigator.userAgent;
      // var booking_data = this.booking_details;
      // console.log("FileUpload list", this.booking_details.amount);

      var booking_data = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        dateOfAppointment: this.booking_details.dateOfAppointment,
        amountToBePaid: this.booking_details.amount,
        doctorId: this.booking_details.doctorId,
        timeSlot: this.booking_details.timeSlot,
        bookedByName: this.booking_details.bookedByName,
        bookedForId: this.booking_details.bookedForId,
        description: this.booking_details.description,
        fileUploadList: this.booking_details.fileUploadList,
        appointmentType: this.booking_details.appointmentType,
        referralCode: this.referralCode
      };
      booking_data.tradeMerchant = 'KBZ';
      // if (screen.width >= 520 && this.freeConsultation == false) {
      //   booking_data.tradeType = "PAY_BY_QRCODE";
      // } else {
      //   booking_data.tradeType = "PWAAPP";
      // }
      // if(this.mobileBrowser == true) {
      //   booking_data.tradeType = "PWAAPP";
      // }
      // TODO CHECK THE LOGIN HERE ALL IF CONDITION AND CONCLUDE IF ITS CORRECT, AND WONT BREAK ANYTHING.
      if (this.miniApp === false ) {
        if(this.selectedPaymentRadio == 'kbzPay' && this.selectedPaymentMethod == 'KBZQR') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PAY_BY_QRCODE";
            booking_data.tradeMerchant = "KBZ"
          }
        } else if (this.selectedPaymentRadio == 'kbzPay' && this.selectedPaymentMethod == 'KBZREQ') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PWAAPP";
            booking_data.tradeMerchant = "KBZ"
          }
        } else if(this.selectedPaymentRadio === 'ayaPay' && this.selectedPaymentMethod == 'AYAQR') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PAY_BY_QRCODE";
            booking_data.tradeMerchant = "AYA"
          }
        } else if(this.selectedPaymentRadio === 'ayaPay' && this.selectedPaymentMethod == 'AYAREQ') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PAY_BY_REQUEST";
            booking_data.tradeMerchant = "AYA"
            booking_data.appPhoneNumber = this.appPhoneNumber
          }
        } else if(this.selectedPaymentRadio === 'CBPay' && this.selectedPaymentMethod == 'CBPayQR') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PAY_BY_QRCODE";
            booking_data.tradeMerchant = "CBPAY"
          }
        } else if(this.selectedPaymentRadio === 'CBPay' && this.selectedPaymentMethod == 'CBPayREQ') {
          if (this.freeConsultation == false) {
            booking_data.tradeType = "PAY_BY_REQUEST";
            booking_data.tradeMerchant = "CBPAY"
            // booking_data.appPhoneNumber = this.appPhoneNumber
          }
        }
      }
      

      booking_data.amountToBePaid = this.couponStatus || this.packageStatus || this.offerStatus
        ? this.amountAfterDiscount
        : booking_data.amountToBePaid;
      booking_data.offerReferenceCode = this.couponStatus
        ? this.couponCode
        : "";
      if(this.offerStatus) {
        booking_data.offerReferenceCode = this.booking_details.offerReferenceCode
      }
      this.corporate_details = Object.keys(this.corporate_details).length ? this.corporate_details : this.booking_details.corporate_full_info;
      booking_data.offerReferenceCode = this.packageStatus ? this.corporate_details.corporatePackage._id : this.couponStatus ? this.couponCode : this.offerStatus ? this.booking_details.offerReferenceCode : "";
      booking_data.offerType = this.packageStatus ? "CORPORATE" : this.couponStatus ? this.booking_details.offerType : this.offerStatus ? this.booking_details.offerType : "";
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/bookAppointmentTwilio", booking_data)
        .then((bookAppointmentResponse) => {
          if (bookAppointmentResponse.data.message == "SUCCESS") {
            // let booking_data = bookAppointmentResponse.data.data;

            localStorage.removeItem("fileStatus");
            localStorage.removeItem("BookingDetails");
            if (bookAppointmentResponse.data.payment_data == "NO_PAYMENT") {
              if (bookAppointmentResponse.data.data.payment_status == "PAID") {
                this.$router.push({
                  path:
                    "/customer/bookedSuccess?merch_order_id=" +
                    bookAppointmentResponse.data.data._id,
                });
              }
            } else if (
              !bookAppointmentResponse.data.payment_data.qr_code &&
              !bookAppointmentResponse.data.payment_data.trade_type
            ) {
              localStorage.removeItem("BookingDetails");
              var pwa_signed_url =
                bookAppointmentResponse.data.payment_data.PWA_signed_url;
              window.location.replace(pwa_signed_url);
            } else if (
              !bookAppointmentResponse.data.payment_data.qr_code &&
              bookAppointmentResponse.data.payment_data.trade_type ==
                "APPH5"
            ) {
              this.paymentStatus = true;
              try {
                kbzpay
                  .startPay(
                    bookAppointmentResponse.data.payment_data.prepay_id,
                    bookAppointmentResponse.data.payment_data.orderInfo,
                    bookAppointmentResponse.data.payment_data.sign
                  )
                  .then((startPaySuccess) => {
                    localStorage.removeItem("BookingDetails");
                    // console.log(startPaySuccess);
                  })
                  .catch((startPayError) => {
                    localStorage.removeItem("BookingDetails");
                    // console.log(startPayError);
                  });
              } catch (Exception) {
                console.log(Exception);
                localStorage.removeItem("BookingDetails");
              }
              var queryOrderBody = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
                booking_id: bookAppointmentResponse.data.data._id,
                // trade_status: "",
              };
              this.query_order(queryOrderBody);
            } else if (bookAppointmentResponse.data.payment_data.qr_code) {
              var qr_code =
                bookAppointmentResponse.data.payment_data.qr_code;
                this.$router.push({
                  name: "QRCode",
                  params: {
                    booking_id: bookAppointmentResponse.data.data._id,
                    qr_code: qr_code,
                    paymentGateway: (booking_data.tradeMerchant == 'KBZ') ? 'kbzPay': ((booking_data.tradeMerchant == 'CBPAY') ? 'CBPay' : 'ayaPay'),
                    amount: this.couponStatus || this.packageStatus || this.offerStatus
                      ? this.amountAfterDiscount
                      : this.booking_details.amount,
                  },
                });
            } else if(bookAppointmentResponse.data.payment_data.trade_type === 'PAY_BY_REQUEST' && booking_data.tradeMerchant == 'CBPAY') {
                let CBPayUATDeepLink = bookAppointmentResponse.data.payment_data.deepLinkURL;
              window.location.replace(CBPayUATDeepLink);
                var queryOrderBody = {
                  token: this.currentCustomer,
                  typeOfUser: "CUSTOMER",
                  booking_id: bookAppointmentResponse.data.data._id,
                  // trade_status: "",
                };
                this.query_order(queryOrderBody);
            } else if(bookAppointmentResponse.data.payment_data.trade_type === 'PAY_BY_REQUEST' && bookAppointmentResponse.data.payment_data.request_payment_data) {
                alert('We have sent a request to your AYA Pay app for payment')
                var queryOrderBody = {
                  token: this.currentCustomer,
                  typeOfUser: "CUSTOMER",
                  booking_id: bookAppointmentResponse.data.data._id,
                  // trade_status: "",
                };
                this.query_order(queryOrderBody);
            }
          } else if (bookAppointmentResponse.data.message == "ERROR") {
            alert("Slot full");
          }
        })
        .catch((bookAppointmentError) => {
          if (bookAppointmentError.response.status == 305) {
            this.slotBooked = true;
          }
          else if(bookAppointmentError.response.status == 411)
          {
            this.restart_app = true;
          }
          else if (bookAppointmentError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
    },
    redirectToAyaPaymentApp() {
        this.paymentLoading = true;
        var changeConsentBody = {
            'consentStatus' : 'Accepted',
            // 'customerId': this.customer_id,
            'token' : this.currentCustomer,
            'typeOfUser' : "CUSTOMER"
        }
        if(this.customer_name == 'Guest' && this.check1)
        {
            axios.post(process.env.VUE_APP_BACKEND_URL+"/postConsentAcceptance", changeConsentBody)
            .then((changeSuccess) => {
            })
            .catch((changeError) =>{
            });
        }
      this.isBookSlot = true;
      var userAgent = navigator.userAgent;
      // var booking_data = this.booking_details;
      // console.log("FileUpload list", this.booking_details.amount);

      var booking_data = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        dateOfAppointment: this.booking_details.dateOfAppointment,
        amountToBePaid: this.booking_details.amount,
        doctorId: this.booking_details.doctorId,
        timeSlot: this.booking_details.timeSlot,
        bookedByName: this.booking_details.bookedByName,
        bookedForId: this.booking_details.bookedForId,
        description: this.booking_details.description,
        fileUploadList: this.booking_details.fileUploadList,
        appointmentType: this.booking_details.appointmentType
      };
      booking_data.tradeMerchant = 'AYA';
      booking_data.tradeType = "PAY_BY_AYA_REQUEST";
      booking_data.appPhoneNumber = this.ayaPhoneNumber;
      
      
      // booking_data.createdBy = "CUSTOMER";
      // booking_data.createdById = this.customer_id;
      // booking_data.couponCode = this.couponStatus?this.couponCode:''
      booking_data.amountToBePaid = this.couponStatus || this.packageStatus || this.offerStatus
        ? this.amountAfterDiscount
        : booking_data.amountToBePaid;
      booking_data.offerReferenceCode = this.couponStatus
        ? this.couponCode
        : "";
      if(this.offerStatus) {
        booking_data.offerReferenceCode = this.booking_details.offerReferenceCode
      }
      // booking_data.offerReferenceCode = this.couponStatus
      //   ? this.couponCode
      //   : "";
      this.corporate_details = Object.keys(this.corporate_details).length ? this.corporate_details : this.booking_details.corporate_full_info;
      booking_data.offerReferenceCode = this.packageStatus ? this.corporate_details.corporatePackage._id : this.couponStatus ? this.couponCode : this.offerStatus ? this.booking_details.offerReferenceCode : "";
      booking_data.offerType = this.packageStatus ? "CORPORATE" : this.couponStatus ? this.booking_details.offerType : this.offerStatus ? this.booking_details.offerType : "";
      // booking_data.corporateEmployeeId = this.packageStatus ? this.corporate_details.corporateEmployeeId : "";
      // booking_data.corporatePackageId = this.packageStatus ? this.corporate_details.corporatePackage._id : "";
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/bookAppointmentTwilio", booking_data)
        .then((bookAppointmentResponse) => {
          if (bookAppointmentResponse.data.message == "SUCCESS") {
            // let booking_data = bookAppointmentResponse.data.data;

            localStorage.removeItem("fileStatus");
            localStorage.removeItem("BookingDetails");
            if (bookAppointmentResponse.data.payment_data == "NO_PAYMENT") {
              if (bookAppointmentResponse.data.data.payment_status == "PAID") {
                this.$router.push({
                  path:
                    "/customer/bookedSuccess?merch_order_id=" +
                    bookAppointmentResponse.data.data._id,
                });
              }
            } else if((bookAppointmentResponse.data.payment_data.trade_type === 'PAY_BY_REQUEST' || bookAppointmentResponse.data.payment_data.trade_type === 'PAY_BY_AYA_REQUEST')  && bookAppointmentResponse.data.payment_data.request_payment_data) {
                alert('We have sent a request to your AYA Pay app for payment')
              var queryOrderBody = {
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
                booking_id: bookAppointmentResponse.data.data._id,
                // trade_status: "",
              };
              this.query_order(queryOrderBody);
            } else {
              alert('SOMETHING WENT WRONG');
            }
          } else if (bookAppointmentResponse.data.message == "ERROR") {
            alert("Slot full");
          }
        })
        .catch((bookAppointmentError) => {
          if (bookAppointmentError.response.status == 305) {
            this.slotBooked = true;
          }
          else if(bookAppointmentError.response.status == 411)
          {
            this.restart_app = true;
          }
          else if (bookAppointmentError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
    },
    cancelCoupon() {
      if(!this.packageStatus)
      {
        let localData = localStorage.getItem("BookingDetails");
        this.booking_details = JSON.parse(localData);
        this.booking_details.amountToBePaid = "";
        this.booking_details.offerType = "";
        this.amountAfterDiscount = "";
        this.offerApplied = false;
        this.offerStatus = false;
        localStorage.setItem(
          "BookingDetails",
          JSON.stringify(this.booking_details)
        );
        this.couponApplied = false;
        this.$nextTick(()=>{
          this.couponStatus = false;
          this.offerStatus = false;
        });
        this.couponCode = "";
        this.offerReferenceCode = "";
        if(this.booking_details && this.booking_details.corporate_full_info && this.booking_details.corporate_full_info.length > 1)
        {
          this.multiple_packages_notification = true;
        } else if(this.booking_details && this.booking_details.corporate_full_info && this.booking_details.corporate_full_info.length === 0 && this.allPromoList.length > 1) {
          this.multiple_offers_notification = true;
        }
      }
      else
      {
        let localData = localStorage.getItem("BookingDetails");
        this.booking_details = JSON.parse(localData);
        if(this.booking_details)
        this.booking_details.amountToBePaid = "";
        this.booking_details.offerType = "";
        this.booking_details.corp_cancelled = false;
        this.booking_details.offerReferenceCode = "";
        this.booking_details.frequency = null;
        localStorage.setItem(
          "BookingDetails",
          JSON.stringify(this.booking_details)
        );
        this.packageApplied = false;
        this.$nextTick(()=>{
          this.packageStatus = false;
        });
      }
      // console.log("End of cancel coupon")
    },
    redirectToMyOffers() {
      // console.log("Offers page called")
      localStorage.setItem(
        "BookingDetails",
        JSON.stringify(this.booking_details)
      );
      this.$router.push({
        name: "MyOffers",
        //  params: { booking_details_from_params: this.booking_details },
      });
    },
    onClick(e){
        var createClickLogBody = {
          token: this.currentCustomer,
          typeOfUser: 'CUSTOMER',
          clientX: String(e.clientX),
          clientY: String(e.clientY),
          pageName: 'VerifyAppointment'
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/createClickLog",createClickLogBody);
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'verifyAppointment',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'verifyAppointment',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
  mounted() {
    var userAgent = navigator.userAgent;
    this.platform = localStorage.getItem('platform');
    this.ayaPhoneNumber = localStorage.getItem('ayaPhoneNumber');
    if (userAgent.includes("kbzpay")) {
      this.show_header = false;
      this.miniApp = true;
    } else if(screen.width < 520) {
      this.mobileBrowser = true;
    }
    this.isMobile = this.$device.mobile;
    if (this.$store.state.locale == "en") document.title = "Verify Appointment";
    else document.title = "ရက်ချိန်းအတည်ပြုခြင်း";
    if (this.$store.state.locale !== "") {
      this.$i18n.locale = this.$store.state.locale;
    } else {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");

    var access = this.$cookies.get("access");

    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      //logging page hit - start
      let x =  localStorage.getItem("currentURL")
      let pageName = "VerifyAppointmentScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName);
      let pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody);
      //logging page hit - end

      let params = this.$route.params.booking_details_from_params;
      if (params || localStorage.getItem("BookingDetails")) {
        if (localStorage.getItem("BookingDetails")) {
          let localData = localStorage.getItem("BookingDetails");
          this.booking_details = JSON.parse(localData);
          if ((typeof(this.booking_details.amountToBePaid) == 'number') && (this.booking_details.offerType == "COUPON" || this.booking_details.offerType == "PROMOTION")) {
            this.couponStatus = true;
            this.couponApplied = true;
            this.couponCode = this.booking_details.offerReferenceCode;
            this.amountAfterDiscount = this.booking_details.amountToBePaid > 0 ? this.booking_details.amountToBePaid: 0 ;
            this.discountAmount = this.booking_details.amount - this.booking_details.amountToBePaid;
          } else if(this.booking_details.offerType == "CORPORATE") {
            this.amountAfterDiscount = this.booking_details.amountToBePaid > 0 ? this.booking_details.amountToBePaid : 0;
            this.packageStatus = true;
            this.packageApplied = true;
            this.discountAmount = this.booking_details.amount - this.booking_details.amountToBePaid;
            this.corporatePackageName = this.booking_details.corporate_full_info.corporatePackage.corporate_program_name;
          }
        } else {
          this.booking_details = params;
          localStorage.setItem("BookingDetails", JSON.stringify(this.booking_details));
        }

        let data = {
          token: this.currentCustomer,
          typeOfUser: "CUSTOMER",
          doctorId: this.booking_details.doctorId,
        };
      
        axios.post(process.env.VUE_APP_BACKEND_URL + "/customerVerifyAppointmentPage", data).then((successResponse) => {
          // this.customer_id = successResponse.data.customer_data._id;
          this.customer_name = successResponse.data.customer_data.customer_name;
          this.$i18n.locale = successResponse.data.customer_data.language;
          this.$store.dispatch('changeLocale', this.$i18n.locale);
          this.consent_acceptance = successResponse.data.customer_data.consent_acceptance;
          this.kbzEmployee =
            successResponse.data.customer_data.fam_of_kbzEmployee ||
            successResponse.data.customer_data.kbzEmployee
              ? "true"
              : "false";
          this.freeConsultation = successResponse.data.count > 0 ? true : false;

          // Check Corporate Package Cancel.
          let check_corp_cancel;
          if(localStorage.getItem("BookingDetails")) {
            check_corp_cancel = JSON.parse(localStorage.getItem("BookingDetails")).corp_cancelled;
            // console.log('check_corp_cancel : ', check_corp_cancel);
          } else {
            check_corp_cancel = true;
          }

          if(successResponse.data.corporate_data.length == 1 && !(!isNaN(Number(this.booking_details.amountToBePaid)) && this.booking_details.offerType) && check_corp_cancel != false) {
            this.booking_details.discountAmount = (this.booking_details.amount - successResponse.data.corporate_data[0].amount_to_be_paid) > 0 ? this.booking_details.amount - successResponse.data.corporate_data[0].amount_to_be_paid : 0;
            this.booking_details.amountToBePaid = successResponse.data.corporate_data[0].amount_to_be_paid > 0 ? successResponse.data.corporate_data[0].amount_to_be_paid : 0;
            this.booking_details.offerReferenceCode = successResponse.data.corporate_data[0].corporatePackage._id;
            this.booking_details.offerType = "CORPORATE";
            this.booking_details.frequency = successResponse.data.corporate_data[0].frequency;
            this.booking_details.frequencyUsed = successResponse.data.corporate_data[0].frequency_used;
            this.booking_details.corporate_full_info = successResponse.data.corporate_data[0].corporatePackage;
            localStorage.setItem("BookingDetails", JSON.stringify(this.booking_details));

            this.corporate_details = successResponse.data.corporate_data[0];
            this.amountAfterDiscount = this.corporate_details.amount_to_be_paid;
            this.packageStatus = true;
            this.packageApplied = true;
            this.discountAmount = this.booking_details.discountAmount;
            this.corporatePackageName = this.corporate_details.corporatePackage.corporate_program_name;
          } else if(successResponse.data.corporate_data.length > 1) {
            if(this.booking_details.offerType != "COUPON" && this.booking_details.offerType != "PROMOTION"){
              this.multiple_packages_notification = true;
              // this.corporate_details = successResponse.data.corporate_data;
              // this.booking_details.corporate_full_info = successResponse.data.corporate_data;
              // localStorage.setItem("BookingDetails",JSON.stringify(this.booking_details));
            }
          } else if (successResponse.data.corporate_data.length === 0 &&  successResponse.data.promo_data.promoLength === 1 && !(!isNaN(Number(this.booking_details.amountToBePaid)) && this.booking_details.offerType) && check_corp_cancel != false) {
              let promoToApply = successResponse.data.promo_data.promoRecords[0];
              let discount_value = 0;
              if (promoToApply.discount_type === 'PERCENTAGE_OFF') {
                  discount_value = (this.booking_details.amount * promoToApply.discount)/100;
                  if (discount_value > promoToApply.maximum_discount) {
                    discount_value = promoToApply.maximum_discount;
                  }
              } else if (promoToApply.discount_type === 'FLAT_OFF') {
                if ((this.booking_details.amount - promoToApply.discount) < 0 ) {
                  discount_value = this.booking_details.amount;
                } else {
                  discount_value = promoToApply.discount;
                }
              }
              this.booking_details.discountAmount = discount_value;
              this.booking_details.amountToBePaid = parseInt(this.booking_details.amount - discount_value, 10);
              this.booking_details.offerType = "PROMOTION";
              this.booking_details.offerFullInfo = promoToApply;
              this.booking_details.offerReferenceCode = promoToApply.sk.split('#')[1];
              localStorage.setItem("BookingDetails", JSON.stringify(this.booking_details));
              this.amountAfterDiscount = this.booking_details.amountToBePaid;
              this.discountAmount = this.booking_details.discountAmount;
              this.offerAutoApplied = true;
              this.offerStatus = true;
              this.offerApplied = true;
              this.offerName = promoToApply.offer_name;
          } else if (successResponse.data.corporate_data.length === 0 &&  successResponse.data.promo_data.promoLength > 1) {
              this.multiple_offers_notification = true;
              this.allPromoList = successResponse.data.promo_data;
          }
          this.isLoadingAppointmentDetails = false;

          if (!this.multiple_packages_notification) {
            if (this.booking_details.frequency != null) {
              this.frequencyPopup = true;
            }
          }
          this.userPageViewTable();
        }).catch((errorResponse) => {
          console.log("[ERROR] customerVerifyAppointmentPage error : ", errorResponse);
          if (errorResponse.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
      } else {
        // Popup & redirect to Home Screen.
        this.cannotProceedFlag = true;
      }
    }
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border: 1px solid #c9e5ff90 !important;
  width: 90%;
  margin: auto;
}
.css-title {
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}
.content {
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
  color: #333333;
  line-height: 24px;
}
.header {
  padding: 16px 20px !important;
  box-shadow: 0px 2px 15px 0px #00000026;
  margin-bottom: 24px;
}
.v-input__append-inner {
  margin-top: 10px !important;
}
.v-messages .theme--light {
  background-color: black;
}
.flexRowClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.myOfferClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e4f5ff !important;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(5, 71, 137, 0.1) !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
  height: 55px;
}
.myOfferappliedClass {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e4f5ff !important;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(5, 71, 137, 0.1) !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
  /* background-color: aqua !important; */
  /* height: 55px; */
}
.offerdescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.couponCodeDescription {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #219653;
  margin-top: 2px;
}
.discountedAmountDescription {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #eb5757;
  margin-right: 8px;
  margin-top: 2px;
}
.OffertitleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.myOffertitle {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.offersTextCalss {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}
.arrowClass {
  margin-top: 5px;
  padding: 5px;
  margin-right: 10px;
}
.multiplePackagesClass {
  margin-top: -30px;
  background-color: rgb(234, 244, 255);
  padding: 5px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px
}
.centered-placeholder input {
  line-height: 60px; /* Match the height of the text field */
  padding: 0; /* Remove unnecessary padding */
}
.paymentGatewayContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 16px; */
  border: 1px solid #ededed;
  margin-top: 10px;
  border-radius: 8px;
}
.gatewayBox {
  padding: 8px 16px 8px 16px;
  margin: 8px;

  border: 1px solid #ededed;
  border-radius: 8px;
}
.selectPaymentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
  background: #f4f6fa;
}
.v-input {
  margin: 0;
}
.v-input >>> .v-messages {
  display: none;
}
.v-input >>> .v-text-field__details {
  display: none;
}
.generateQRBOX {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e5e9;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  gap: 10px;
  cursor: pointer;
  
}
</style>
